const blogList = [
  {
    image: "images/blog/01.jpg",
    date: "October 30, 2022",
    category: "Business",
    title: "Create Stunning Websites Like a Pro With Taypo",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae sed. Potenti aliquam feugiat proin facilisi. ac nunc always made uniquely calibrated.",
  },
  {
    image: "images/blog/02.jpg",
    date: "October 30, 2022",
    category: "Sass",
    title: "10 Simple Practices That Will Help You Get Better",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae always made uniquely",
  },
  {
    image: "images/blog/03.jpg",
    date: "October 30, 2022",
    category: "Sass",
    title: "Made World-Class Design With Taypo products",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae always made uniquely",
  },
  {
    image: "images/blog/03.jpg",
    date: "October 30, 2022",
    category: "Sass",
    title: "Made World-Class Design With Taypo ",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae always made uniquely",
  },
  {
    image: "images/blog/03.jpg",
    date: "October 30, 2022",
    category: "Sass",
    title: "Made World-Class Design With",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae always made uniquely",
  },
  {
    image: "images/blog/03.jpg",
    date: "October 30, 2022",
    category: "Sass",
    title: "Made World-Class Design",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae always made uniquely",
  },
  {
    image: "images/blog/03.jpg",
    date: "October 30, 2022",
    category: "Sass",
    title: "Made World-Class",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae always made uniquely",
  },
  {
    image: "images/blog/03.jpg",
    date: "October 30, 2022",
    category: "Sass",
    title: "Made World",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae always made uniquely",
  },
  {
    image: "images/blog/03.jpg",
    date: "October 30, 2022",
    category: "Sass",
    title: "Made",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae always made uniquely",
  },
  {
    image: "images/blog/03.jpg",
    date: "October 30, 2022",
    category: "Sass",
    title: "Made World-Class Design With Taypo products abcd",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae always made uniquely",
  },
  {
    image: "images/blog/03.jpg",
    date: "October 30, 2022",
    category: "Sass",
    title: "Made World-Class Design With Taypo products efgh",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae always made uniquely",
  },
  {
    image: "images/blog/03.jpg",
    date: "October 30, 2022",
    category: "Sass",
    title: "Made World-Class Design With Taypo products hijk",
    url: "blog-single.html",
    description:
      "Highly at nisi nam vestibulum ut eget vitae always made uniquely",
  },
];

export default blogList;
