const tags = [
  "Agency",
  "Web Design",
  "Saas",
  "Corporate",
  "Creative",
  "Software",
  "Landing",
  "Startup",
];
export default tags;
