const articles = [
  {
    title: "Create Stunning Websites Like a Pro With Taypo",
    imageSrc: "images/blog/01.jpg",
    date: "October 30, 2022",
  },
  {
    title: "10 Simple Practices That Will Help You Get Better",
    imageSrc: "images/blog/02.jpg",
    date: "October 30, 2022",
  },
  {
    title: "Made World-Class Design With Taypo products",
    imageSrc: "images/blog/03.jpg",
    date: "October 30, 2022",
  },
];
export default articles;
