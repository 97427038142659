export default [
  {
    id: 1,
    name: "Women Lather Jacket",
    image: "images/product/01.jpg",
    stock: 32,
    price: 3500,
    salePrice: 2500,
    description:
      "Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
    rating: 5,
    tags: ["Women"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Red"],
  },
  {
    id: 2,
    name: "Women Sweater",
    image: "images/product/02.jpg",
    stock: 32,
    price: 2400,
    discount: 600,
    salePrice: 8,
    description:
      "Burgundy mini crossbody bag. Oval shape. Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
    rating: 5,
    tags: ["Women"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Purple"],
  },
  {
    id: 3,
    name: "Gold Framed Sunglasses",
    image: "images/product/03.jpg",
    stock: 64,
    price: 1200,
    discount: 300,
    salePrice: 900,
    description:
      "Versatile gold unisex sunglasses from Sundown, that offer protection from harmful UV rays while rendering a comfortable and stylish fit SIZE GUIDE",
    rating: 5,
    tags: ["Men"],
    size: ["54"],
    category: "Men",
    colors: ["Gold"],
  },
  {
    id: 4,
    name: "Unpaired Running Shoe",
    image: "images/product/04.jpg",

    stock: 18,
    price: 3200,
    discount: 600,
    salePrice: 2600,
    description: "Unpaired Running Shoe",
    rating: 5,
    tags: ["Shoes"],
    size: ["M", "XL"],
    category: "Men",
    colors: ["White"],
  },
  {
    id: 5,
    name: "Luxury Fashion Bag",

    image: "images/product/05.jpg",

    stock: 18,
    price: 1900,
    discount: 600,
    salePrice: 1300,
    description:
      "Burgundy mini crossbody bag. Oval shape. Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
    rating: 3,
    tags: ["Women", "Bags"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Purple"],
  },

  {
    id: 6,
    name: "Bright Hipster Cloth",
    image: "images/product/06.jpg",
    stock: 18,
    price: 3200,
    discount: 600,
    salePrice: 2600,
    description:
      "Burgundy mini crossbody bag. Oval shape. Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
    rating: 5,
    tags: ["Women"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Purple"],
  },
  {
    id: 7,
    name: "Men's Brand Tshirts",
    image: "images/product/01.jpg",
    stock: 18,
    price: 1800,
    discount: 600,
    salePrice: 1200,
    description:
      "Slim fit textured smart shirt featuring a regular collar,long sleeves with buttoned cuffs,a button-up front hidden by a placket and a label appliqu on the inside of the hem.",
    rating: 5,
    tags: ["Men"],
    size: ["M", "XL"],
    category: "Men",
    colors: ["Black"],
  },
  {
    id: 8,
    name: "Crewneck Sweatshirt",
    image: "images/product/02.jpg",
    stock: 18,
    price: 2600,
    discount: 600,
    salePrice: 2200,
    description:
      "Burgundy mini crossbody bag. Oval shape. Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
    rating: 5,
    tags: ["Men"],
    size: ["M", "XL"],
    category: "Men",
    colors: ["Red"],
  },
  {
    id: 9,
    name: "iphone 11 Pro",
    image: "images/product/03.jpg",
    stock: 18,
    price: 1900,
    discount: 600,
    salePrice: 1300,
    description:
      "iPhone 11 Pro smartphone was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
    rating: 5,
    tags: ["Electronic"],
    size: ["12"],
    category: "Electronic",
    colors: ["Black"],
  },
  {
    id: 10,
    name: "Film Roll And Kodak",
    image: "images/product/04.jpg",
    stock: 18,
    price: 1200,
    discount: 300,
    salePrice: 900,
    description:
      "Film Roll And Kodak smartphone was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
    rating: 5,
    tags: ["Electronic"],
    size: ["12"],
    category: "Electronic",
    colors: ["Orange"],
  },
  {
    id: 11,
    name: "Amazon Echo Speaker",
    image: "images/product/05.jpg",
    stock: 18,
    price: 1900,
    discount: 600,
    salePrice: 1300,
    description:
      "Amazon Echo Speaker smartphone was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
    rating: 5,
    tags: ["Electronic"],
    size: ["12"],
    category: "Electronic",
    colors: ["White"],
  },
  {
    id: 12,
    name: "Canon EOS EF 75",
    image: "images/product/06.jpg",
    stock: 18,
    price: 2400,
    discount: 600,
    salePrice: 1800,
    description:
      "Canon EOS EF 75 was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
    rating: 5,
    tags: ["Electronic"],
    size: ["12"],
    category: "Electronic",
    colors: ["Black"],
  },
  {
    id: 13,
    name: "Headphone Amplifier",
    image: "images/product/01.jpg",
    stock: 18,
    price: 1200,
    discount: 300,
    salePrice: 900,
    description:
      "Headphone Amplifier was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
    rating: 5,
    tags: ["Electronic"],
    size: ["12"],
    category: "Electronic",
    colors: ["Black"],
  },
  {
    id: 14,
    image: "images/product/02.jpg",
    pictures: ["electronic/product/06.jpg", "electronic/product/06.jpg"],
    stock: 18,
    price: 1200,
    discount: 300,
    salePrice: 900,
    description:
      "Bright Hipster Cloth was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
    rating: 5,
    tags: ["Electronic"],
    size: ["12"],
    category: "Electronic",
    colors: ["Black"],
  },
  {
    id: 15,
    name: "Women Speaker",
    image: "images/product/03.jpg",
    stock: 18,
    price: 1900,
    discount: 600,
    salePrice: 1300,
    description:
      "Women Speaker was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
    rating: 5,
    tags: ["Electronic"],
    size: ["12"],
    category: "Electronic",
    colors: ["White"],
  },
  {
    id: 16,
    name: "Men's Speaker",
    image: "images/product/04.jpg",

    stock: 18,
    price: 4700,
    discount: 600,
    salePrice: 1300,
    description:
      "Men's Speaker was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
    rating: 5,
    tags: ["Electronic"],
    size: ["12"],
    category: "Electronic",
    colors: ["White"],
  },
  {
    id: 17,
    name: "Leather Chair",
    image: "images/product/05.jpg",

    stock: 18,
    price: 3500,
    discount: 1000,
    salePrice: 2500,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Furniture"],
    size: ["12"],
    category: "Furniture",
    colors: ["Gold"],
  },
  {
    id: 18,
    name: "Luxury Royal Chair",
    image: "images/product/05.jpg",

    stock: 18,
    price: 2400,
    discount: 400,
    salePrice: 1800,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Furniture"],
    size: ["12"],
    category: "Furniture",
    colors: ["Gold"],
  },
  {
    id: 19,
    name: "Brown Study Table Chair",
    image: "images/product/06.jpg",
    stock: 18,
    price: 1200,
    discount: 300,
    salePrice: 900,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Furniture"],
    size: ["12"],
    category: "Furniture",
    colors: ["Gold"],
  },
  {
    id: 20,
    name: "Red Royal Chair",
    image: "images/product/01.jpg",

    stock: 18,
    price: 3200,
    discount: 1000,
    salePrice: 2600,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Furniture"],
    size: ["12"],
    category: "Furniture",
    colors: ["Red"],
  },
  {
    id: 21,
    name: "Dining Chairs",
    image: "images/product/02.jpg",

    stock: 18,
    price: 1900,
    discount: 500,
    salePrice: 1300,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Furniture"],
    size: ["12"],
    category: "Furniture",
    colors: ["Green"],
  },
  {
    id: 22,
    name: "Bright Hipster Cloth",
    image: "images/product/02.jpg",
    stock: 18,
    price: 4200,
    discount: 500,
    salePrice: 3700,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Furniture"],
    size: ["12"],
    category: "Furniture",
    colors: ["Blue"],
  },
  {
    id: 23,
    name: "Comfortable Armchair",
    image: "images/product/03.jpg",
    stock: 18,
    price: 3500,
    discount: 1000,
    salePrice: 2500,
    description:
      "Comfortable Armchair was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
    rating: 5,
    tags: ["Furniture"],
    size: ["12"],
    category: "Furniture",
    colors: ["Gold"],
  },

  {
    id: 25,
    name: "Teddy Stuffed Bear",
    image: "images/product/04.jpg",

    stock: 18,
    price: 3500,
    discount: 1000,
    salePrice: 2500,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Kids"],
    size: ["12"],
    category: "Kids",
    colors: ["Gold"],
  },
  {
    id: 26,
    name: "Close up of Toy Blocks",
    image: "images/product/05.jpg",

    stock: 18,
    price: 4200,
    discount: 500,
    salePrice: 3700,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Kids"],
    size: ["12"],
    category: "Kids",
    colors: ["Gold"],
  },
  {
    id: 27,
    name: "Little Girl With Teddy ",
    image: "images/product/06.jpg",

    stock: 18,
    price: 1800,
    discount: 300,
    salePrice: 1500,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Kids"],
    size: ["12"],
    category: "Kids",
    colors: ["Blue"],
  },
  {
    id: 28,
    name: "Easter Rabbit Toys",
    image: "images/product/01.jpg",

    stock: 18,
    price: 1900,
    discount: 600,
    salePrice: 1300,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Kids"],
    size: ["12"],
    category: "Kids",
    colors: ["Blue"],
  },
  {
    id: 29,
    name: "Baby Mittens & Toys",
    image: "images/product/03.jpg",

    stock: 18,
    price: 1900,
    discount: 500,
    salePrice: 1300,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Kids"],
    size: ["12"],
    category: "Kids",
    colors: ["Pink"],
  },
  {
    id: 30,
    name: "Toy Plane With Paper",
    image: "images/product/02.jpg",

    stock: 18,
    price: 4200,
    discount: 500,
    salePrice: 3700,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Kids"],
    size: ["12"],
    category: "Kids",
    colors: ["Pink"],
  },
  {
    id: 31,
    name: "Stuffed Animal Toy",
    image: "images/product/04.jpg",

    stock: 18,
    price: 3500,
    discount: 1000,
    salePrice: 2500,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Kids"],
    size: ["12"],
    category: "Kids",
    colors: ["Pink"],
  },
  {
    id: 32,
    name: "Baby Playing Toys",
    image: "images/product/05.jpg",

    stock: 18,
    price: 1800,
    discount: 300,
    salePrice: 1500,
    description:
      "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
    rating: 5,
    tags: ["Kids"],
    size: ["12"],
    category: "Kids",
    colors: ["Pink"],
  },

  {
    id: 33,
    name: "OVAL MINI CROSSBODY BAG",
    image: "images/product/06.jpg",
    stock: 32,
    price: 2290,
    discount: 500,
    salePrice: 1790,
    description:
      "Burgundy mini crossbody bag. Oval shape. Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
    rating: 3,
    tags: ["Women", "Bags"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Purple"],
  },
  {
    id: 34,
    name: "TIE-DYE FABRIC BUCKET BAG",
    image: "images/product/01.jpg",

    stock: 46,
    price: 2290,
    discount: 500,
    salePrice: 1790,
    description:
      "Bucket bag in a combination of colours. Tie-dye print on the body. Front pocket. Shoulder straps. Two pockets in lining. Zip closure.",
    rating: 4,
    tags: ["Women", "Bags"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Gray", "Red"],
  },
  {
    id: 35,
    name: "CANVAS TOTE BAG",
    image: "images/product/01.jpg",
    stock: 20,
    price: 2290,
    discount: 500,
    salePrice: 1790,
    description:
      "Beige tote bag. Made of natural materials. Canvas exterior. Contrast-coloured trims. Handles with slogan zip pulls. Lined interior with zip pocket.",
    rating: 4,
    tags: ["Women", "Bags"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Gray"],
  },
  {
    id: 36,
    name: "BRAIDED CROSSBODY BAG",
    image: "images/product/03.jpg",

    stock: 15,
    price: 2990,
    discount: 700,
    salePrice: 2290,
    description:
      "BLACK crossbody bag. Braided exterior featuring decorative seashells,a chain trim and fringing around the front flap. Chain shoulder strap. Lined interior with pocket. Magnetic clasp closure.",
    rating: 2,
    tags: ["Women", "Bags"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Black"],
  },
  {
    id: 37,
    name: "CROSSBODY BAG",
    image: "images/product/06.jpg",

    stock: 5,
    price: 2290,
    discount: 500,
    salePrice: 1790,
    description:
      "Crossbody bag in a combination of colours. Made of natural materials. Jute exterior in contrast materials with a polka dot print. Wooden kiss-lock frame",
    rating: 3,
    tags: ["Women", "Bags"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Beige"],
  },
  {
    id: 38,
    name: "MARBLE-EFFECT MINAUDIRE",
    image: "images/product/05.jpg",

    stock: 12,
    price: 3990,
    discount: 1000,
    salePrice: 2990,
    description:
      "Minaudire in a combination of colours. Marble-effect exterior with circular patterns. Metal detail on the contour. Chain shoulder strap. Lined interior. Metal clasp closure.",
    rating: 5,
    tags: ["Women", "Bags"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Pink"],
  },
  {
    id: 39,
    name: "NATURAL GLOSSY BRAIDED CROSSBODY BAG",
    image: "images/product/01.jpg",

    stock: 23,
    price: 2890,
    discount: 600,
    salePrice: 2290,
    description:
      "Red crossbody bag made of natural materials. Braided body with a glossy finish. Gold metal detail on the front. Shoulder strap. Lined interior. Magnetic clasp with strap closure.",
    rating: 2,
    tags: ["Women", "Bags"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Red"],
  },
  {
    id: 40,
    name: "NATURAL MULTICOLOURED WOVEN HANDBAG",
    image: "images/product/02.jpg",

    stock: 34,
    price: 2890,
    discount: 600,
    salePrice: 2290,
    description:
      "Multicoloured handbag. Woven body in contrast colours and materials. Metal trim along the edge. Chain shoulder strap. Lined interior. Clasp closure lined with beads.",
    rating: 5,
    tags: ["Women", "Bags"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Red"],
  },
  {
    id: 41,
    name: "BLUE COLLECTION GLITTER MINI CROSSBODY BAG",
    image: "images/product/04.jpg",

    stock: 3,
    price: 2890,
    discount: 600,
    salePrice: 2290,
    description:
      "Mini crossbody bag available in silver,fuchsia and BLUE. Glittery exterior. Satin-finish handles. Chain shoulder strap. Lined interior. Magnetic clasp closure.",
    rating: 4,
    tags: ["Women", "Bags"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Blue"],
  },
  {
    id: 42,
    name: "FRINGED TOTE BAG",
    image: "images/product/03.jpg",

    stock: 42,
    price: 4990,
    discount: 2000,
    salePrice: 2990,
    description:
      "Tan tote bag. Split suede fringing on the exterior. Shoulder straps. Lined interior with pocket. Zip closure.",
    rating: 3,
    tags: ["Women", "Bags"],
    size: ["M", "XL"],
    category: "Women",
    colors: ["Brown"],
  },

  {
    id: 43,
    name: "SPORTY HOODIE WITH NEON",
    image: "images/product/02.jpg",

    stock: 15,
    price: 1590,
    discount: 318,
    salePrice: 1272,
    description:
      "High neck sweatshirt with long sleeves. Featuring injected zip on the yoke,front pouch pocket with a slogan print and appliqu detail,and print on the back.",
    rating: 1,
    tags: ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
    size: ["M", "Y"],
    category: "Kids",
    colors: ["Orange"],
  },
  {
    id: 44,
    name: "CHAMBRAY KNIT BLAZER",
    image: "images/product/01.jpg",

    stock: 12,
    price: 2790,
    discount: 558,
    salePrice: 2232,
    description:
      "Knit blazer with a lapel collar,V-neckline,long sleeves,button fastening and front pockets in contrasting velvet.",
    rating: 2,
    tags: ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
    size: ["M", "Y"],
    category: "Kids",
    colors: ["Gray"],
  },
  {
    id: 45,
    name: "SLIM FIT STRIPED SHIRT",
    image: "images/product/03.jpg",

    stock: 6,
    price: 1590,
    discount: 318,
    salePrice: 1272,
    description:
      "Slim fit smart shirt featuring a regular collar long sleeves with buttoned cuffs,a button-up front and a label appliqu on the inside of the hem.",
    rating: 4,
    tags: ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
    size: ["M", "Y"],
    category: "Kids",
    colors: ["Blue"],
  },
  {
    id: 46,
    name: "SLIM FIT TEXTURED SHIRT",
    image: "images/product/01.jpg",

    stock: 11,
    price: 1590,
    discount: 318,
    salePrice: 1272,
    description:
      "Slim fit textured smart shirt featuring a regular collar,long sleeves with buttoned cuffs,a button-up front hidden by a placket and a label appliqu on the inside of the hem.",
    rating: 1,
    tags: ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
    size: ["M", "Y"],
    category: "Kids",
    colors: ["Red"],
  },
  {
    id: 47,
    name: " STAR WARS T-SHIRT",
    image: "images/product/05.jpg",

    stock: 5,
    price: 990,
    discount: 198,
    salePrice: 792,
    description:
      "T-shirt with a round neckline and long sleeves. Featuring an all-over  STAR WARS print and label at the hem.",
    rating: 5,
    tags: ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
    size: ["M", "Y"],
    category: "Kids",
    colors: ["Black"],
  },
  {
    id: 48,
    name: "STRIPED T-SHIRT",
    image: "images/product/02.jpg",

    stock: 15,
    price: 690,
    discount: 138,
    salePrice: 552,
    description:
      "Round neck T-shirt featuring long sleeves,a striped print and a chest pocket.",
    rating: 3,
    tags: ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
    size: ["M", "Y"],
    category: "Kids",
    colors: ["Green"],
  },
  {
    id: 49,
    name: "SEQUINNED  STAR WARS T-SHIRT",
    image: "images/product/04.jpg",
    stock: 8,
    price: 1090,
    discount: 218,
    salePrice: 872,
    description:
      "T-shirt with a round neckline and long sleeves. Featuring a  STAR WARS print on the front with reversible sequins and label at the hem.",
    rating: 4,
    tags: ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
    size: ["M", "Y"],
    category: "Kids",
    colors: ["Green"],
  },
  {
    id: 50,
    name: "LENTICULAR  STAR WARS T-SHIRT",
    image: "images/product/05.jpg",

    stock: 12,
    price: 1090,
    discount: 218,
    salePrice: 872,
    description:
      "T-shirt with a round neckline and long sleeves. Featuring a  STAR WARS print on the front with lenticular detail to create the illusion of movement",
    rating: 2,
    tags: ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
    size: ["M", "Y"],
    category: "Kids",
    colors: ["Blue"],
  },
  {
    id: 51,
    name: "SPORTY T-SHIRT WITH NEON STRIPES",
    image: "images/product/06.jpg",

    stock: 8,
    price: 990,
    discount: 198,
    salePrice: 792,
    description:
      "Long sleeve T-shirt with a round neckline. Featuring a chest pocket and a printed stripe on the front with neon detailing.",
    rating: 2,
    tags: ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
    size: ["M", "Y"],
    category: "Kids",
    colors: ["Red"],
  },
  {
    id: 52,
    name: "SNOW CAMOUFLAGE PRINT JACKET",
    image: "images/product/01.jpg",

    stock: 8,
    price: 3990,
    discount: 1000,
    salePrice: 2990,
    description:
      "Jacket with an adjustable hood in the collar and long sleeves with elastic cuffs. Patch pockets with flaps and contrast taping at waist. Adjustable drawstring hem.",
    rating: 2,
    tags: ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
    size: ["S", "M", "L", "XL"],
    category: "men",
    colors: ["Red"],
  },
  {
    id: 53,
    name: "Jacket WITH POCKETS",
    image: "images/product/02.jpg",

    stock: 8,
    price: 3990,
    discount: 1000,
    salePrice: 2990,
    description:
      "Shirt-style jacket with long sleeves and buttoned cuffs. Features a faded effect on the seams,patch pockets with flap on the chest and hips and a frayed hem.",
    rating: 1,
    tags: ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
    size: ["S", "M", "L", "XL"],
    category: "men",
    colors: ["Khaki"],
  },
  {
    id: 54,
    name: "PINSTRIPED DENIM OVERSHIRT",
    image: "images/product/02.jpg",
    stock: 7,
    price: 3990,
    discount: 2000,
    salePrice: 1990,
    description:
      "Collared overshirt with long sleeves and buttoned cuffs. Featuring a chest patch pocket and a zip-up front.",
    rating: 1,
    tags: ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
    size: ["S", "M", "L", "XL"],
    category: "men",
    colors: ["Khaki"],
  },
];
